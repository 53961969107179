import { Stage } from "./configuration";

export const CONSULTATION_BROADCAST_CHANNEL = "consultation_started";

const BETA_QUITANIEVES_ENDPOINT =
  "bzb2snmm53.execute-api.us-west-2.amazonaws.com";
const PROD_QUITANIEVES_ENDPOINT =
  "sdihol5j2j.execute-api.us-west-2.amazonaws.com";

const QUITANIEVES_ENDPOINTS = {
  dev: BETA_QUITANIEVES_ENDPOINT,
  beta: BETA_QUITANIEVES_ENDPOINT,
  gamma: BETA_QUITANIEVES_ENDPOINT,
  prod: PROD_QUITANIEVES_ENDPOINT,
};

export function getQuitanievesEndpoint(stage: Stage): string {
  return `https://${QUITANIEVES_ENDPOINTS[stage]}`;
}
