import { MetricsDataWithTitle } from "@amzn/it-support-connect-api-model";
import { Credentials } from "aws-sdk/lib/core";
import React from "react";
import { render, unmountComponentAtNode } from "react-dom";

import {
  ChatTranscript,
  getChatTranscripts as getChatTranscriptsImpl,
  getRealTimeChatTranscripts,
} from "./private/chat-transcripts";
import { Main } from "./private/components/Main";
import { Configuration, Stage } from "./private/configuration";
import {
  AmazonConnectStreamsApi,
  OnCcpInitCallback,
} from "./private/connect/api";
import { logger } from "./private/logger";
import {
  getAllReaderboardMetricsData as getAllReaderboardMetricsDataImpl,
  getReaderboardMetricsData as getReaderboardMetricsDataImpl,
} from "./private/readerboard-metrics-data";
import { createTracker } from "./private/snowplow";

export { ApiError } from "@amzn/it-support-connect-api-model";

export interface ConfigInitOptions {
  /** The AWS credentials. */
  readonly credentials: Credentials;

  /**
   * The stage.
   * @default "beta"
   */
  readonly stage?: Stage;
}

export interface UiInitOptions {
  /** The HTMLElement where the agent.js UI will be placed. */
  readonly container: HTMLElement;

  /** An optional callback that will be executed when the CCP has initialized. */
  readonly onCcpInit?: OnCcpInitCallback;

  /** An optional callback that will be executed when the "close" icon is clicked */
  readonly onCloseClick?: () => void;

  connectInstanceCcpUrl?: string;

  /**
   * Option whether to periodically reconfigure the softphone in the given intervals.
   * Value must be the interval in seconds. Interval cannot be less than 2.
   * @experimental
   */
  watchSoftphoneIntervalInSeconds?: number;
}

export interface InitOptions {
  /** The CCP initialization options. */
  ccp?: UiInitOptions;

  /** The configuration options. */
  config: ConfigInitOptions;

  connectInstanceCcpUrl?: string;
}

// See: https://github.com/amazon-connect/amazon-connect-streams/blob/c50075eb376174adb7802e3a7a7569313ebf7de4/src/streams.js#L225
interface Conduit {
  sendUpstream(eventName: "configure", data: unknown): void;
}

const conduitFactory = () =>
  (connect.core as { upstream?: Conduit }).upstream as Conduit;

/**
 * Periodically reconfigure the softphone to prevent losing access to it on CCP init.
 * This is a HACK but recommended by Connect to get around the error "Error while accepting an incoming contact"
 * due to agents mic not working. It is a best effort fix which may or may not resolve the issue.
 * See: https://sim.amazon.com/issues/CONNECT-9951
 *
 * @param onCcpInit The default init CCP callback to wrap
 * @param seconds The period in seconds to reinitialize the softphone
 * @param connectCore The connect.Core object for interacting with Connect. @VisibleForTesting
 */
export function watchSoftphoneOnCcpInit(
  onCcpInit: OnCcpInitCallback | undefined,
  seconds: number | undefined,
  getConduit: typeof conduitFactory = conduitFactory
): OnCcpInitCallback | undefined {
  return (api: AmazonConnectStreamsApi) => {
    const upstream = getConduit();
    const params = {
      "connect.core.upstream": upstream,
      watchSoftphoneIntervalInSeconds: seconds,
    };

    if (!onCcpInit) return;

    if (!upstream || !seconds || seconds < 2) {
      logger.info(`Softphone will NOT be automatically reconfigured`, params);
      onCcpInit(api);
      return;
    }

    logger.info(
      `Softphone will be automatically reconfigured every ${seconds} seconds.`
    );

    logger.info(
      "Starting up new softphone watcher. Logs will be written every 12 attempts."
    );

    let attempts = 0;

    const configureSoftphone = () => {
      if (upstream && upstream.sendUpstream) {
        try {
          upstream.sendUpstream("configure", {
            softphone: {
              allowFramedSoftphone: true,
            },
          });
          // log this on start and every minute elapsed
          if (attempts % 12 === 0) {
            logger.info(`Softphone reconfigured. Attempt ${attempts}`);
          }
          attempts++;
        } catch (err) {
          logger.error(
            `Error occurred while reconfiguring softphone. Attempt ${attempts}`
          );
        } finally {
          setTimeout(configureSoftphone, seconds * 1000);
        }
      }
    };

    setTimeout(configureSoftphone, seconds * 1000);
    onCcpInit(api);
  };
}

let container: HTMLElement | null = null;

/**
 * Initializes agent.js
 * @param options The init options
 */
export function init(options: InitOptions | UiInitOptions): void {
  let ccp: UiInitOptions | undefined;
  let connectInstanceCcpUrl: string | undefined;
  if ("config" in options) {
    const { config } = options;
    Configuration.init(config.credentials, config.stage || "beta");
    ccp = options.ccp;
    connectInstanceCcpUrl = options.connectInstanceCcpUrl;
  } else {
    ccp = options;
  }

  createTracker(Configuration.instance);

  if (ccp && !container) {
    render(
      <Main
        onCcpInit={watchSoftphoneOnCcpInit(
          ccp.onCcpInit,
          ccp.watchSoftphoneIntervalInSeconds
        )}
        onCloseClick={ccp.onCloseClick}
        connectInstanceCcpUrl={connectInstanceCcpUrl}
      />,
      ccp.container
    );
    container = ccp.container;
  }
}

/**
 * Terminates agent.js
 * @param options The termination options
 */
export function terminate(): void {
  if (container) {
    unmountComponentAtNode(container);
    container = null;
  }
}

/**
 * Gets the chat transcripts of a contact.
 * @param contactId The contact id
 * @throws `ApiError` If a 4XX exception is thrown by the client.
 */
export function getChatTranscripts(
  contactId: string
): Promise<ChatTranscript[]> {
  return getChatTranscriptsImpl(contactId);
}

/**
 * Get metrics data for IT Readerboard.
 * @param group group option to determine aggregation rule.
 * @param filter filter option to determine team.
 */
export function getReaderboardMetricsData(
  group: string,
  filter: string
): Promise<MetricsDataWithTitle> {
  return getReaderboardMetricsDataImpl(group, filter);
}

/**
 * Get all (primary and secondary) metrics data for IT Readerboard.
 * @param group group option to determine aggregation rule.
 * @param filter filter option to determine team.
 */
export function getAllReaderboardMetricsData(
  group: string,
  filter: string
): Promise<MetricsDataWithTitle[]> {
  return getAllReaderboardMetricsDataImpl(group, filter);
}

/**
 * The realtime chat transcript of the contact.
 * @param contactId  The id of the contact to obtain the transcript
 * @throws `ApiError` If a 4XX exception is thrown by the client.
 */
export function getRealTimeChatTranscript(
  contactId: string
): Promise<ChatTranscript[] | undefined> {
  return getRealTimeChatTranscripts(contactId);
}
